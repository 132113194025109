import { doc, updateDoc, onSnapshot, query, getDoc, collection, getDocs, setDoc, where, limit, arrayUnion } from "firebase/firestore";
import Auth from "./Auth";
import fbdb from "./fbdb";
import { setUserProfile } from "../redux/UserStateSlice";
import { setAdminMenu, setMobileMenu, setUserMenu } from "../redux/AppUISlice";
import { setIotCommands, setIotData, setSampling, setSiteMap } from "../redux/BuildingSlice";
import { getParams } from "../../components/utils/SetupApp";
import { setContent, setSettingsByKey } from "../redux/ContentSlice";

export function subsUserProfile(dispatch) {
  const q = query(doc(fbdb, "Users", Auth.currentUser.uid));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    dispatch(setUserProfile(snapshot.data()));
  });
  return () => unsubscribe();
}

export function changeBuildingInDb(building) {
  const q = query(doc(fbdb, "Users", Auth.currentUser.uid));
  updateDoc(q, { "lastViewed.Building": building });
}

export function setMenuById(id, dispatch) {
  if (id) {
    const q = query(doc(fbdb, "Menus", id));
    getDoc(q).then((doc) => {
      const { userMenu, adminMenu, mobile } = doc.data();
      dispatch(setUserMenu(userMenu ?? []));
      dispatch(setAdminMenu(adminMenu ?? []));
      dispatch(setMobileMenu(mobile ?? []));
    });
  } else {
    dispatch(setUserMenu([]));
    dispatch(setAdminMenu([]));
    dispatch(setMobileMenu([]));
  }
}

export function getSiteMap(path, dispatch) {
  if (path) {
    const q = query(doc(fbdb, path));
    getDoc(q).then((doc) => {
      const { Iots, sampling, content } = doc.data();

      dispatch(setSiteMap({ Iots } ?? {}));
      dispatch(setIotCommands(Iots.calls));
      dispatch(setSampling(sampling ?? 0));
      dispatch(setContent(content));
    });
  }
}

export function executeDocCalls(dispatch, docCalls) {
  // eslint-disable-next-line no-unused-vars
  const params = getParams();

  for (const iot in docCalls) {
    // eslint-disable-next-line no-eval
    const path = eval(docCalls[iot].path);
    const iotKey = docCalls[iot].iotKey;
    const q = query(doc(fbdb, path));
    getDoc(q).then((doc) => {
      let data = doc.data();
      if (iotKey && data) data = data[iotKey];
      dispatch(setIotData({ data, iot }));
    });
  }
}

export function executeCollectionCalls(dispatch, collectionCalls) {
  for (const iot in collectionCalls) {
    const path = collectionCalls[iot].path;
    let q = query(collection(fbdb, path));
    const filters = collectionCalls[iot].filters;

    q = filterQuery(filters, q);
    getDocs(q).then(({ docs }) => {
      const data = docs.map((e) => ({ id: e.id, ...e.data() }));
      dispatch(setIotData({ data, iot }));
    });
  }
}

export function executeCollectionSitemapCalls(dispatch, collectionCalls) {
  for (const iot in collectionCalls) {
    const path = collectionCalls[iot].path;
    let q = query(collection(fbdb, path));
    const filters = collectionCalls[iot].filters;

    q = filterQuery(filters, q);
    getDocs(q).then(({ docs }) => {
      const data = docs.map((e) => ({ id: e.id, ...e.data() }));
      data.forEach((document) => {
        dispatch(setIotData({ data: document, iot: document.id }));
      });
    });
  }
}

export function subsCollections(dispatch, paths) {
  for (const iot in paths) {
    const path = paths[iot].path;
    let q = query(collection(fbdb, path));
    const filters = paths[iot].filters;

    q = filterQuery(filters, q);
    const unsubscribe = onSnapshot(q, ({ docs }) => {
      const data = docs.map((e) => ({ id: e.id, ...e.data() }));
      dispatch(setIotData({ data, iot }));
    });
    return () => unsubscribe();
  }
}

function filterQuery(filters, q) {
  let result = q;
  if (filters) {
    filters.forEach((filter) => {
      switch (filter.type) {
        case "where":
          result = query(q, where(filter.field, filter.operator, filter.value));
          break;
        case "limit":
          result = query(q, limit(filter.value));
          break;
        case "wherePath":
          const fieldValue = window.location.pathname.split("/")[filter.value + 1];
          result = query(q, where(filter.field, "==", fieldValue));
          break;
        default:
          break;
      }
    });
  }
  return result;
}

export async function getBackupItemsByProject(dispatch, locations) {
  if (locations.length) {
    const data = [];
    for (const path of locations) {
      const q = query(collection(fbdb, `${path}/OhItems`));
      const { docs } = await getDocs(q);
      data.push(...docs.map((e) => formatBackupItem(e, path)));
    }
    dispatch(setSettingsByKey({ data, key: "itemsList" }));
  }
}

function formatBackupItem(item, path) {
  let { label, AnalyserDecimal, AnalyserMf } = item.data();
  if (label === undefined) {
    label = "Not labeled";
  }
  const value = `${path}/OhItems/${item.id}`;
  return { value, label, AnalyserDecimal, AnalyserMf };
}

export async function getBackupDataByPath(dispatch, paths) {
  for (const path of paths) {
    const q = query(doc(fbdb, path));
    getDoc(q).then((doc) => {
      const data = doc.data();
      dispatch(setIotData({ data, iot: path }));
    });
  }
}

export function setDocumentByPath(path, data) {
  const q = query(doc(fbdb, path));
  setDoc(q, data);
}

export async function getDocumentByPath(path) {
  const q = query(doc(fbdb, path));
  const result = await getDoc(q);
  return result.data();
}

export async function appendArrayInDocByPath(path, arrayKey, data) {
  const docRef = doc(fbdb, path);
  const q = query(docRef);
  const oldDoc = await getDoc(q);

  if (oldDoc.exists()) {
    await updateDoc(docRef, { [arrayKey]: arrayUnion(data) });
  } else {
    setDoc(q, { [arrayKey]: [data] });
  }
}

export async function getMaxDemandByPathAndEndDate(path, endDate, numberOfMonths) {
  const startDate = new Date(endDate.getFullYear(), endDate.getMonth() - numberOfMonths, endDate.getDate(), endDate.getHours());
  const q = query(collection(fbdb, `${path}/Data`), where("DateTime", ">", startDate), where("DateTime", "<", endDate));
  const { docs } = await getDocs(q);
  let result = 0;
  docs.forEach((doc) => {
    if (doc.data().data !== "No data") {
      const maxDatapoint = doc.data().data.reduce((a, b) => Math.max(a, b.state), 0);
      result = Math.max(result, maxDatapoint);
    }
    // if (doc.data().data !== "No data" && result < Math.max(result, doc.data().aggregates.maximums.days[0])) {
    //   result = Math.max(result, doc.data().aggregates.maximums.days[0]);
    // }
  });
  return result;
}
