import { createTheme } from "@mui/material";
import { blue, grey, orange } from "@mui/material/colors";

export const flavour = getFlavour(process.env.REACT_APP_EC3_FLAVOUR);

function getFlavour(client) {
  const flavours = {
    ec3: {
      pageTitle: "EC3 Platform",
      favicon: `${window.location.origin}/flavours/ec3/logo.svg`,
      brandlogo1: "./flavours/ec3/logo.svg",
      brandlogo2: "./flavours/ec3/Brand2.png",
      palette: {
        light: {
          mode: "light",
          primary: { main: "#003a70", contrastText: "#fff" },
          secondary: { main: "#00adee", contrastText: "#000" },
          neutral: { main: grey[[300]] },
          background: { default: "#f0f0f0", paper: "#f0f0f0" },
        },
        dark: {
          mode: "dark",
          primary: { main: orange[700], contrastText: "#fff" },
          secondary: { main: blue[300], contrastText: "#fff" },
          neutral: { main: grey[[300]] },
          background: { default: "#000", paper: "#000" },
        },
      },
      typography: {
        fontFamily: "NeoSansProRegular",
      },
      login: {
        logo: "./flavours/ec3/logo.svg",
        background: "/flavours/ec3/login_bg.jpg",
        loginText: "EC3 Platform",
      },
    },
    nmh: {
      pageTitle: "Beachcomber Platform",
      favicon: `${window.location.origin}/flavours/nmh/logo.svg`,
      brandlogo1: "./flavours/nmh/logo.svg",
      brandlogo2: "./flavours/nmh/Brand2.png",
      palette: {
        light: {
          mode: "light",
          primary: { main: "#003465", contrastText: "#fff" },
          secondary: { main: "#AC8C3E", contrastText: "#000" },
          neutral: { main: grey[[300]] },
          background: { default: "#f0f0f0", paper: "#f0f0f0" },
        },
        dark: {
          mode: "dark",
          primary: { main: orange[700], contrastText: "#fff" },
          secondary: { main: blue[300], contrastText: "#fff" },
          neutral: { main: grey[[300]] },
          background: { default: "#000", paper: "#000" },
        },
      },
      typography: {
        fontFamily: "NeoSansProRegular",
      },
      login: {
        logo: "./flavours/nmh/logo.svg",
        background: "/flavours/nmh/login_bg.jpg",
        loginText: "Beachcomber Platform",
      },
    },
    hc2: {
      pageTitle: "HC2 Platform",
      favicon: `${window.location.origin}/flavours/hc2/logo.svg`,
      brandlogo1: "./flavours/hc2/logo.svg",
      brandlogo2: "./flavours/hc2/Brand2.png",
      palette: {
        light: {
          mode: "light",
          primary: { main: "#003465", contrastText: "#fff" },
          secondary: { main: "#AC8C3E", contrastText: "#000" },
          neutral: { main: grey[[300]] },
          background: { default: "#f0f0f0", paper: "#f0f0f0" },
        },
        dark: {
          mode: "dark",
          primary: { main: orange[700], contrastText: "#fff" },
          secondary: { main: blue[300], contrastText: "#fff" },
          neutral: { main: grey[[300]] },
          background: { default: "#000", paper: "#000" },
        },
      },
      typography: {
        fontFamily: "NeoSansProRegular",
      },
      login: {
        logo: "./flavours/hc2/logo.svg",
        background: "/flavours/hc2/login_bg.jpg",
        loginText: "Feel @ Home",
      },
    },
    mc2: {
      pageTitle: "MC2 Platform",
      favicon: `${window.location.origin}/flavours/mc2/logo.svg`,
      brandlogo1: "./flavours/mc2/logo.svg",
      brandlogo2: "./flavours/mc2/Brand2.png",
      palette: {
        light: {
          mode: "light",
          primary: { main: "#003a70", contrastText: "#fff" },
          secondary: { main: "#00adee", contrastText: "#000" },
          neutral: { main: grey[[300]] },
          background: { default: "#f0f0f0", paper: "#f0f0f0" },
        },
        dark: {
          mode: "dark",
          primary: { main: orange[700], contrastText: "#fff" },
          secondary: { main: blue[300], contrastText: "#fff" },
          neutral: { main: grey[[300]] },
          background: { default: "#000", paper: "#000" },
        },
      },
      typography: {
        fontFamily: "NeoSansProRegular",
      },
      login: {
        logo: "./flavours/mc2/logo.svg",
        background: "/flavours/mc2/login_bg.jpg",
        loginText: "MC2 Platform",
      },
    },
    constance: {
      pageTitle: "Constance EC3 Platform",
      favicon: `${window.location.origin}/flavours/constance/logo.svg`,
      brandlogo1: "./flavours/constance/logo.svg",
      brandlogo2: "./flavours/constance/Brand2.png",
      palette: {
        light: {
          mode: "light",
          primary: { main: "#003a70", contrastText: "#fff" },
          secondary: { main: "#00adee", contrastText: "#000" },
          neutral: { main: grey[[300]] },
          background: { default: "#f0f0f0", paper: "#f0f0f0" },
        },
        dark: {
          mode: "dark",
          primary: { main: orange[700], contrastText: "#fff" },
          secondary: { main: blue[300], contrastText: "#fff" },
          neutral: { main: grey[[300]] },
          background: { default: "#000", paper: "#000" },
        },
      },
      typography: {
        fontFamily: "NeoSansProRegular",
      },
      login: {
        logo: "./flavours/constance/logo.svg",
        background: "/flavours/constance/login_bg.jpg",
        loginText: "Constance EC3 Platform",
      },
    },
  };
  return flavours[client];
}

function setFlavour() {
  // Set HTML title
  document.title = flavour.pageTitle;

  // Set Favicon
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = flavour.favicon;

  // Set Theme
  const theme = createTheme({
    palette: flavour.palette.light,
    typography: flavour.typography,
  });

  return theme;
}

export default setFlavour;
